.custom_input_form_wrapper_style {
  position: relative;
  width: 100%;
  border-radius: 10px;
  border: solid 1px $inputBorderColor;

  &:hover {
    border: solid 1px $secColor;
  }

  .custom_input_form_style {
    height: 48px;
    padding: 16px;
    background: transparent;
    color: $secColor;
    @include Roboto-font-style(14px);

    &::placeholder {
      color: #b7c0cd;
      opacity: 1;
      /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #b7c0cd;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #b7c0cd;
    }
  }

  label {
    position: absolute;
    background-color: white;
    @include Roboto-font-style(14px);
    font-weight: 600;
    color: $secColor;
    bottom: 38px;
    left: 15px;
  }

  .input_form_icon_component_style {
    padding: 0px 16px;
  }
}

.input_dropdown_style {
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 354px;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px $inputBorderColor;
  background-color: white;
  margin-top: 14px;
  padding: 24px;

  h3 {
    @include Roboto-font-style(16px);
    font-weight: 500;
    color: $textColor;
    margin-bottom: 18px;
  }

  .dropdown_result_style {
    padding: 8px;
    @include Roboto-font-style(14px);
    color: $secColor;
    margin-bottom: 10px;
    border-radius: 8px;
    border: solid 1px transparent;
    cursor: pointer;

    &:hover {
      border-radius: 8px;
      border: solid 1px $mainColor;
      color: $mainColor;
    }
  }
}