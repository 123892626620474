.queud_order_wrapper_style {
  padding: 17px 70px;
  box-shadow: 0px 20px 24px 0 rgba(65, 28, 0, 0.17);
  background-color: white;
  align-items: end;

  h4 {
    @include ITC-Avant-Garde-Gothic-font-style(28px);
    color: $secColor;
  }

  .order_content_style {
    border-radius: 20px;
    border: solid 1px rgba(64, 141, 246, 0.63);
    padding: 3px 18px;

    span {
      color: #015080;
      @include ITC-book-font-style(20px);
    }
  }

  .carousel__slider {
    margin: 0 50px;
  }

  .buttonBack,
  .buttonNext {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 50%;
    background-color: $mainColor;
    height: 37px;

    svg {
      color: white;
    }

    &:disabled {
      background-color: $secColor;
    }
  }

  .buttonBack {
    left: 0;
  }

  .buttonNext {
    right: 0;
  }
}
