.cash_drawer_modal_wrapper_style {
  h2,
  p {
    color: #39485b;
    @include Roboto-font-style(20px);
  }

  label {
    @include Roboto-font-style(24px);
    font-weight: 600;
    color: #000;
  }

  .input_label_style {
    @include Roboto-font-style(20px);
    font-weight: 500;
    color: #39485b;
  }

  .margin_24px_style {
    margin-top: 24px;
  }

  .open_drawer_btn_style,
  .closeing_station_btn_style {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 100%;
    border-radius: 10px;
    padding: 12px;
    @include Roboto-font-style(20px);
    font-weight: 500;
  }

  .open_drawer_btn_style {
    border: solid 1px rgba(39, 56, 70, 0.63);
    background-color: transparent;
    color: #273846;
  }

  .closeing_station_btn_style {
    border: solid 1px $mainColor !important;
    background-color: $mainColor !important;
    color: white;
  }

  hr {
    margin-top: 27px;
    margin-bottom: 32px;
    border-bottom: 1px solid #d9d9d9;
  }

  .warning_section_wrapper_style {
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
      @include Roboto-font-style(20px);
      font-weight: 500;
      color: $goldishColor;
      margin-top: 24px;
      margin-bottom: 8px;
    }

    p {
      @include Roboto-font-style(20px);
      color: $textColor;
      text-align: center;
      margin-bottom: 24px;
    }
  }

  .recount_btn_style,
  .continue_over_short_btn_style {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    border-radius: 10px;
    @include Roboto-font-style(20px);
    font-weight: 500;
  }

  .recount_btn_style {
    color: white;
    border: 1px solid $mainColor;
    background-color: $mainColor;
    margin-bottom: 14px;
  }

  .continue_over_short_btn_style {
    color: #39485b;
    border: 1px solid $inputBorderColor;
    background-color: $inputBorderColor;
  }

  .drawer_cash_notes_section_style {
    label {
      @include Roboto-font-style(20px);
      font-weight: 500;
      color: $mainColor;
      margin-bottom: 18px;
      text-transform: capitalize;
    }

    textarea {
      height: 218px;
      padding: 24px;
      width: 100%;
      background-color: transparent;
      border-radius: 10px;
      border: dashed 2px $inputBorderColor;
      @include Roboto-font-style(14px);
      margin-bottom: 24px;

      &:hover,
      &:focus {
        border-color: $secColor;
        color: $secColor;
      }

      &::placeholder {
        color: #b7c0cd;
        opacity: 1;
        /* Firefox */
      }

      &::-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #b7c0cd;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #b7c0cd;
      }
    }
  }
}
