.station_content_wrapper_style {
  border-radius: 15px;
  border: solid 1px $inputBorderColor;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 18px;

  &:hover {
    border: solid 2px $mainColor;
  }

  .station_name_status_wrapper_style {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: solid 1px $inputBorderColor;
    padding: 16px 24px;
  }

  .station_index_style,
  .station_name_style {
    color: #39485b;
    @include Roboto-font-style(24px);
    font-weight: 600;
  }

  .station_status_style {
    @include Roboto-font-style(16px);
    font-weight: 500;
    color: $textColor;
  }
}

.station_avatar_wrapper_style {
  margin: 18px 24px 0 24px;

  .zero_cashiers_icon_wrapper_style {
    img {
      width: 40px;
      height: 40px;
    }
  }

  .cashiers_quanitiy_style {
    p {
      @include Roboto-font-style(14px);
      font-weight: 500;
      color: #5f6a79;
    }
  }
}

.stations_btn_wrapper_style {
  margin: 18px 24px 24px 24px;

  .go_to_pos_btn_style,
  .close_station_btn_style,
  .open_station_btn_style {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    @include Roboto-font-style(20px);
    font-weight: 500;
    border-radius: 10px;
    padding: 12px;
    text-transform: capitalize !important;
    border-color: transparent !important;
  }

  .close_station_btn_style {
    background-color: $inputBorderColor !important;
    color: #39485b !important;
  }

  .open_station_btn_style {
    background-color: transparent !important;
    color: $mainColor !important;
    border: solid 2px $mainColor !important;
  }

  .go_to_pos_btn_style {
    background-color: $mainColor !important;
  }
}
