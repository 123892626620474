// pagination component style
.page-paginator {
  nav {
    margin-top: 32px;
  }

  .selected,
  .notSelected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    @include Roboto-font-style(13px);
    font-weight: 600;
    line-height: 0 !important;
  }

  .selected {
    color: white !important;
    background-color: $mainColor !important;
  }

  .notSelected {
    color: #333 !important;
    background-color: white !important;
    border: solid 1px $inputBorderColor !important;
  }

  .MuiPaginationItem-ellipsis {
    border-radius: 8px !important;
  }

  .MuiPaginationItem-icon {
    width: 16px;
    height: 16px;
  }
}

.product_list_scroll_style {
  overflow-y: auto;
  height: 1126px;
}

.product_list_style {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(295px, 1fr));
  column-gap: 18px;
  max-height: 1484px;
  overflow-y: auto;

  .product_card_wrapper_style {
    height: 411px;
    border: solid 1px $inputBorderColor;
    border-radius: 15px !important;
    margin-bottom: 14px;

    .product_name_style {
      @include Roboto-font-style(20px);
      color: $secColor;
      font-weight: 500;
      margin: 0 18px 6px 18px;
    }

    .product_quantity_style {
      @include Roboto-font-style(16px);
      font-weight: 500;
      color: #39485b;
      margin: 0 18px 6px 18px;

      span {
        color: $textColor;
      }
    }

    .price_badge_style {
      @include Roboto-font-style(24px);
      font-weight: 600;
      color: $mainColor;
      margin: 0 18px 18px 18px;
    }

    .add_product_to_cart_btn_style {
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px;
      border-radius: 10px;
      border: 1px solid $mainColor !important;
      background-color: $mainColor !important;
      @include Roboto-font-style(20px);
      color: white;
      margin: 0 18px 18px 18px;
    }

    .product_img_wrapper_style {
      height: 250px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      overflow: hidden;
      border-radius: 15px;
      border: solid 1px $inputBorderColor;
      margin: 18px;

      .back_order_badge_style {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 150px;
        width: fit-content;
        height: -moz-fit-content;
        justify-content: center;
        margin: 10px 12px;
        height: 38px;
        padding: 10px;
        border-radius: 8px !important;
        background-color: $navbarIconsBgColor !important;
        color: #39485b !important;

        p {
          @include Roboto-font-style(12px);
          font-weight: 400;
        }

        b {
          @include Roboto-font-style(14px);
        }
      }

      img {
        object-fit: fill;
        min-width: 100%;
        min-height: 216px;
      }
    }
  }
}

.station_store_name_wrapper_style {
  background-color: white;
  margin: 4px 0 32px 0 !important;
  padding: 12px 46px !important;

  a {
    @include Roboto-font-style(14px);
    color: #39485b;

    // &:hover {
    //   text-decoration: none !important;
    // }
  }

  svg {
    fill: $textColor !important;
  }

  .MuiLink-underlineAlways {
    color: $mainColor;
    font-weight: bold;
    text-decoration: none !important;
  }
}

.product_list_station_name_style {
  @include Roboto-font-style(24px);
  font-weight: 600;
  color: #071528;
  margin: 0 0px 32px 46px;
}

.sales_person_id_modal_wrapper_style {
  h2 {
    @include Roboto-font-style(14px);
    color: #39485b;
    margin-bottom: 17px !important;
  }

  .custom_input_style {
    label {
      @include Roboto-font-style(14px);
      color: #b7c0cd;

      &.Mui-focused {
        color: $secColor;
        font-weight: 600;
      }
    }

    input {
      color: #222324;
      @include Roboto-font-style(15px);

      &::placeholder {
        color: #b7c0cd;
        opacity: 1;
        /* Firefox */
      }

      &::-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #b7c0cd;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #b7c0cd;
      }
    }

    .MuiInputBase-root {
      border-radius: 10px;
      height: 48px;

      .MuiOutlinedInput-notchedOutline {
        border-color: $inputBorderColor;
      }

      &.Mui-focused {
        border-color: $secColor;

        .MuiOutlinedInput-notchedOutline {
          border-color: $secColor;
          border-width: 1px !important;
        }
      }
    }
  }

  .closeing_station_btn_style {
    margin-top: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border-radius: 10px;
    background-color: $mainColor;
    border: 1px solid $mainColor;
    @include Roboto-font-style(20px);
    font-weight: 500;
    color: white;
  }
}

.confirm_password_modal_wrapper_style {
  label {
    @include Roboto-font-style(14px);
    color: #b7c0cd;

    &.Mui-focused {
      color: $secColor;
      font-weight: 600;
    }
  }

  .MuiTextField-root {
    margin-bottom: 24px;
  }

  input {
    color: #222324;
    @include Roboto-font-style(15px);

    &::placeholder {
      color: #b7c0cd;
      opacity: 1;
      /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #b7c0cd;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #b7c0cd;
    }
  }

  .MuiInputBase-root {
    border-radius: 10px;
    height: 48px;

    .MuiOutlinedInput-notchedOutline {
      border-color: $inputBorderColor;
    }

    &.Mui-focused {
      border-color: $secColor;

      .MuiOutlinedInput-notchedOutline {
        border-color: $secColor;
        border-width: 1px !important;
      }
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border-radius: 10px;
    @include Roboto-font-style(20px);
    padding: 13px;
    font-weight: 500;
    border: solid 1px $mainColor !important;
    background-color: $mainColor !important;
    color: white;

    &.go_to_pos_btn_style {
      color: #39485b !important;
      margin-top: 14px;
      border: solid 1px $inputBorderColor !important;
      background-color: $inputBorderColor !important;
    }
  }

  div.modal_header_style {
    h2 {
      @include Roboto-font-style(20px);
      font-weight: 500 !important;
      margin-bottom: 24px !important;
    }
  }
}

.terminal_disconnect_modal_wrapper_style {
  label {
    @include Roboto-font-style(20px);
    font-weight: 500;
    color: $errorColor;
    margin-top: 24px;
  }

  p {
    margin-top: 8px;
    margin-bottom: 24px;
    @include Roboto-font-style(20px);
    color: $textColor;
  }

  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border-radius: 10px;
    @include Roboto-font-style(20px);
    padding: 13px;
    font-weight: 500;
    border: solid 1px $mainColor !important;
    background-color: $mainColor !important;
    color: white;

    &.go_to_pos_btn_style {
      color: #39485b !important;
      margin-top: 14px;
      border: solid 1px $inputBorderColor !important;
      background-color: $inputBorderColor !important;
    }
  }
}
