/*--------------------------------------- Start of General styles ---------------------------------------*/
html {
  height: 100%;
}

body {
  min-height: 100%;
  background-color: $navbarIconsBgColor;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  text-align: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

a {
  color: $mainColor;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: $mainColor;
    outline: none;
  }
}

input,
button,
a {
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

input,
button,
textarea {
  outline: none;
}

input {
  width: 100%;
  border: none;

  &:disabled {
    background-color: $navbarIconsBgColor !important;
    border-radius: 10px;
  }

  &::placeholder {
    color: #b7c0cd;
    opacity: 1;
    /* Firefox */
  }

  &::-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #b7c0cd;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #b7c0cd;
  }
}

input[type="file"] {
  height: auto;
  padding: 6px 0.75rem;
}

input[type="text"],
input[type="password"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  resize: vertical;
}

.content_style {
  padding: 0 46px 5px 46px;
  min-height: 100vh;
}

.cursor-pointer {
  cursor: pointer;
}

.btn {
  &.disabled,
  &:disabled {
    opacity: 1;
  }
}

span.show-more-less-clickable {
  color: $mainColor !important;
}

.MuiCircularProgress-colorPrimary {
  color: #2167c3 !important;
}

.error_wrapper_style {
  label {
    color: $errorColor !important;
  }

  input {
    border-color: $errorColor !important;
  }

  div.custom_select_style__control {
    border-color: $errorColor !important;
  }
}

.error_msg_style {
  text-transform: capitalize;
  color: $errorColor;
  @include Roboto-font-style(15px);
}

.MuiAlert-standardError {
  height: 36px;
  line-height: 0 !important;
  border-radius: 6px !important;
  @include Roboto-font-style(12px);
  background-color: rgba(254, 239, 239, 1) !important;
  display: flex;
  padding: 4px 12px;
  color: rgba(218, 20, 20, 1) !important;
  align-items: center;
  margin-top: 4px;
  border: solid 1px transparent !important;
}

.disable_section_style {
  pointer-events: none;
}

.no_result_text_style {
  color: $secColor;
  @include Roboto-font-style(23px);
}

// radio btn style
.radio_label_style {
  .MuiFormControlLabel-label {
    @include Roboto-font-style(16px);
    color: $textColor;
    margin: 0px 32px 0px 0px;
  }

  &.active {
    .MuiFormControlLabel-label {
      color: $secColor;
      font-weight: 600;
    }
  }
}

// Start of Common Swal Style
.swal2-backdrop-show,
.swal2-noanimation {
  background: rgba(80, 104, 136, 0.3) !important;
}

.swal-error-style,
.swal-success-style,
.swal-warning-style {
  &::before {
    content: "";
    height: 12px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .swal2-title,
  .swal2-text {
    text-transform: capitalize !important;
    @include Roboto-font-style(20px);
    font-weight: 600;
  }

  .swal2-title {
    padding-top: 0px;
  }

  .swal2-image {
    margin: 24px auto;
  }

  .swal2-html-container {
    @include Roboto-font-style(20px);
    text-transform: capitalize;

    @media screen and (max-width: 425px) {
      font-size: 1.2rem;
    }

    @media screen and (max-width: 375px) {
      font-size: 1.1rem;
    }
  }

  .swal2-label {
    @include Roboto-font-style(20px);
    margin-top: 1px;

    @media screen and (max-width: 425px) {
      font-size: 0.875rem;
    }

    @media screen and (max-width: 375px) {
      font-size: 0.74rem;
    }
  }

  .swal2-actions {
    font-size: 1rem;

    @media screen and (max-width: 425px) {
      font-size: 0.9rem;
    }

    @media screen and (max-width: 425px) {
      font-size: 0.85rem;
    }
  }

  .swal2-styled {
    width: 80px;
    padding: 7px 0;
  }

  .swal2-confirm {
    background-color: $mainColor !important;
  }

  .list-messages-style {
    text-align: initial;

    li {
      margin-bottom: 10px;
    }
  }

  &.swal2-popup {
    border-radius: 10px !important;
  }
}

// End of Common Swal Style

// Start of Error Swal Color
.swal-error-style {
  .swal2-title {
    color: $errorColor;
  }

  .swal2-text {
    color: $textColor;
  }

  .swal2-button-danger {
    background-color: $errorColor !important;
  }

  .list-messages-style {
    li::marker {
      color: $errorColor;
    }
  }
}

// End of Error Swal Color

// Start of Success Swal Color
.swal-success-style {
  &::before {
    border-radius: 10px;
    background-color: white;
  }

  .swal2-title {
    color: $mainColor;
  }

  .swal2-text {
    color: $secColor;
  }
}

// End of Success Swal Color

// Start of Warning Swal Color
.swal-warning-style {
  .swal2-title {
    color: #f8bb86;
  }
}

// End of Warning Swal Color

// Start of scroll bar style

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $inputBorderColor !important;
  border-radius: 15px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8ebbfb !important;
  border-radius: 15px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $mainColor !important;
}

// End of scroll bar style

/*--------------------------------------- End of General styles ---------------------------------------*/
