.return_items_wrapper_style {
  .order_info_wrapper_style {
    background-color: white;
    border: 1px solid $inputBorderColor;
    border-radius: 15px;
  }

  .order_info_wrapper_style {
    padding: 24px;

    .return_item_status_style {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 13px;
      height: 20px;
      border-radius: 8px;
      background-color: $inputBorderColor;
      @include Roboto-font-style(10.5px);
      color: #313030;
      font-weight: 500;
    }

    h3 {
      color: $secColor;
      @include Roboto-font-style(16px);
      font-weight: 600;
      margin: 32px 0 15px;
    }

    .items_in_this_order_wrapper_style {
      border: 1px solid $inputBorderColor;
      border-radius: 10px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
      padding: 24px;

      h4 {
        color: $mainColor;
        @include Roboto-font-style(16px);
        font-weight: 500;
        margin-bottom: 5px !important;
      }

      h2 {
        color: $secColor;
        @include Roboto-font-style(16px);
        font-weight: 500;
        margin-bottom: 5px !important;
      }

      p {
        color: $textColor;
        @include Roboto-font-style(14px);
        margin-bottom: 0 !important;
      }

      .return_order_price_discount_style {
        @include Roboto-font-style(16px);
        color: $errorColor;
        text-decoration: line-through;
      }

      button {
        @include Roboto-font-style(14px);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $errorColor !important;
        border: 1px solid $errorColor;
        border-radius: 8px !important;
        color: white !important;
        height: 35px;
        padding: 10px;
      }
    }
  }

  .returned_item_detials_wrapper_style {
    .return_reason_wrapper_style {
      margin: 18px;
    }

    h3 {
      color: $errorColor !important;
    }

    .cart_wrapper_style {
      min-height: 500px !important;

      .cart_items_wrapper_style {
        height: 450px !important;
      }
    }

    textarea {
      height: 118px;
      padding: 24px;
      width: 100%;
      background-color: transparent;
      border-radius: 10px;
      border: dashed 2px $inputBorderColor;
      @include Roboto-font-style(14px);

      &:hover,
      &:focus {
        border-color: $secColor;
        color: $secColor;
      }

      &::placeholder {
        color: #b7c0cd;
        opacity: 1;
        /* Firefox */
      }

      &::-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #b7c0cd;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #b7c0cd;
      }
    }
  }
}
