.store_front_wrapper_style {
  padding-top: 32px;
  
  .store_front_cards_wrapper_style {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(416px, 1fr));
    column-gap: 32px;
    height: 197px;
  }

  .store_front_card_wrapper_style {
    border: 1px solid #d6dde6;
    border-radius: 15px;
    margin-bottom: 35px;
    height: 197px;

    &:hover {
      border: 2px solid $mainColor !important;
    }

    .card-title {
      padding: 24px 0 0 24px;
    }

    .card-subtitle {
      padding: 33px 24px 12px 24px;
    }

    .card-text {
      padding: 12px 24px 24px 24px;
    }

    .zero_cashiers_icon_wrapper_style {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      background-color: #d9d9d9;
      border: 1px solid #ecebed;
      border-radius: 40px;
      padding: 3px;
    }
  }

  h4 {
    @include Roboto-font-style(24px);
    color: $secColor;
    font-weight: 600;
    text-transform: capitalize;
  }

  .avatar_group_wrapper_style {
    .MuiAvatarGroup-avatar {
      width: 26px !important;
      height: 26px !important;
      @include Roboto-font-style(12px);
      font-weight: 500;
    }
  }

  .cashiers_quanitiy_style {
    p {
      @include Roboto-font-style(14px);
      color: #5f6a79;
      font-weight: 500;
    }
  }

  .open_store_btn_style {
    color: white;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 12px;
    height: 48px;
    @include Roboto-font-style(20px);
    font-weight: 500;
    text-transform: capitalize;
    border-radius: 10px;
    border-color: $mainColor !important;
    background-color: $mainColor !important;
  }

  .stations_status_quanitiy_style {
    p {
      color: $textColor;
      @include Roboto-font-style(14px);
      font-weight: 500;
    }
  }

  hr {
    color: $inputBorderColor !important;
    opacity: 1 !important;
  }
}

.search_store_fronts_by_name_wrapper_style {
  border: 1px solid $inputBorderColor;
  border-radius: 10px;
  padding: 10.76px 23px;

  input {
    color: #878d96;
    @include Roboto-font-style(15px);

    &::placeholder {
      color: #878d96;
      opacity: 1;
      /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #878d96;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #878d96;
    }
  }
}