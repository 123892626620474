.number_input_wrapper_style {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 10px;
  border: solid 1px $inputBorderColor;

  &:hover {
    border: solid 2px $mainColor;
  }

  input {
    height: 18px;
    text-align: center;
    @include Roboto-font-style(15px);
    font-weight: 600;
    color: $textColor;
  }

  svg {
    cursor: pointer;
    color: #b7c0cd;
    width: 22.5px;
    height: 22.5px;

    &:hover {
      color: $mainColor;
    }
  }
}

.input_Increment_decrement_wrapper_style {
  p {
    @include Roboto-font-style(20px);
    font-weight: 500;
    color: $mainColor;
  }

  .number_input_wrapper_style {
    max-width: 134px;
    max-height: 36px;
  }
}
