.items_combination_wrapper_style {
  .product_detials_img_wrapper_style {
    position: relative;
    margin-bottom: 32px;

    .backorder_badge_style {
      display: flex;
      align-items: center;
      position: absolute;
      width: fit-content;
      width: -moz-fit-content;
      justify-content: center;
      height: 38px;
      padding: 10px;
      border: solid 1px $inputBorderColor;
      border-radius: 8px !important;
      background-color: $navbarIconsBgColor !important;
      color: #39485b !important;

      p {
        @include Roboto-font-style(12px);
        font-weight: 400;
      }

      b {
        @include Roboto-font-style(14px);
      }
    }

    &.simple_item_case_style {
      .backorder_badge_style {
        bottom: 0;
        margin: 32px;
      }

      img {
        width: 100%;
        height: 499px;
        border-radius: 10px;
        border: solid 2px $inputBorderColor;
        object-fit: contain;
        background-color: white;
      }
    }

    &.kit_item_case_style {
      .backorder_badge_style {
        bottom: 132px;
        margin: 32px;
      }

      .image-gallery {
        padding: 4px;
        width: 100%;
        border-radius: 10px;
        border: solid 2px $inputBorderColor;
        background-color: white;

        .image-gallery-slide {
          .image-gallery-image {
            object-fit: fill;
          }
        }

        .image-gallery-thumbnail {
          border-radius: 10px;
          border: solid 1px $inputBorderColor;
          background-color: white;
          padding: 4px;
          margin: 15px 15px 15px 0px;

          &.active,
          &:focus,
          &:hover {
            border-radius: 10px;
            border: solid 1px $inputBorderColor;
            background-color: white;
          }
        }

        .image-gallery-svg {
          width: 40px;
          height: 40px;
        }

        .image-gallery-icon {
          padding: 12px;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
          top: 113% !important;
          color: #39485b;

          &:hover {
            color: $mainColor;
          }
        }
      }
    }
  }

  .product_description_style {
    h5 {
      @include Roboto-font-style(20px);
      font-weight: 500;
      color: $secColor;
      margin-bottom: 10px !important;
    }

    p {
      @include Roboto-font-style(16px);
      color: #39485b;

      span {
        @include Roboto-font-style(16px);
        color: #39485b;
      }
    }
  }

  .product_details_wrapper_style {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 15px;
    border: solid 1px $inputBorderColor;
    background-color: white;
    margin-left: 32px;

    .product_wrapper_scroll_style {
      overflow-y: auto;
      padding: 24px;
      height: 700px;

      h2 {
        @include Roboto-font-style(24px);
        font-weight: 600;
        color: $secColor;
        margin-top: 18px;
        margin-bottom: 0;
      }

      .product_type_style {
        width: fit-content;
        width: -moz-fit-content;
        height: 39px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 8px;
        border: solid 1px $inputBorderColor;
        background-color: $navbarIconsBgColor;
        @include Roboto-font-style(16px);
        margin-bottom: 18px;
        color: $textColor;
      }

      .price_badge_style {
        @include Roboto-font-style(24px);
        font-weight: 600;
        color: $mainColor;
        margin-top: 22px;
      }

      .product_quanitity_wrapper_style {
        label {
          @include Roboto-font-style(16px);
          font-weight: 500;
          color: #39485b;
          margin: 29px 0 9px 0;
        }

        .number_input_wrapper_style {
          max-width: 146px;
          max-height: 48px;
        }
      }

      .combinations_wrapper_style {
        h3 {
          @include Roboto-font-style(16px);
          font-weight: 500;
          color: $secColor;
          margin: 32px 0;
        }

        .MuiTabs-root {
          min-height: 36px !important;
          margin-bottom: 25px;

          .MuiTabs-flexContainer {
            border-bottom: 2px solid #f2f6f8;
          }

          button {
            text-transform: none !important;
            min-height: 36px !important;
            padding: 8.5px 14px !important;
            @include Roboto-font-style(16px);
            color: $textColor;
            font-weight: normal;

            &.Mui-selected {
              color: $mainColor !important;
              font-weight: 500;
            }
          }

          .MuiTabs-indicator {
            background-color: $mainColor !important;
          }
        }
      }
    }

    .product_detials_btns_wrapper_style {
      border-radius: 15px;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
      padding: 24px;

      .back_to_station_btn_style,
      .add_to_cart_btn_style {
        width: 100%;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20.5px 0px;
        border-radius: 10px;
        @include Roboto-font-style(20px);
        font-weight: 500;
      }

      .back_to_station_btn_style {
        color: #39485b !important;
        background-color: $inputBorderColor !important;
        border: 1px solid $inputBorderColor !important;
        margin-bottom: 18px;
      }

      .add_to_cart_btn_style {
        color: white !important;
        background-color: $mainColor !important;
        border: 1px solid $mainColor !important;
      }
    }
  }

  .attributes_wrapper_style {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px $inputBorderColor;
    border-radius: 10px;
    padding: 24px;

    .default_attr_wrapper_style {
      .default_attr_name_style {
        @include Roboto-font-style(14px);
        color: $textColor;
      }

      .reset_custom_combination_btn_style {
        @include Roboto-font-style(14px);
        font-weight: 500;
        color: $errorColor;
        cursor: pointer;
      }
    }

    .choose_custom_attr_wrapper_style {
      .custom_combinations_toggle_btn_style {
        label {
          @include Roboto-font-style(14px);
          font-weight: 500;
          color: #39485b;
          margin: 24px 0px 9px 0px;
        }

        button {
          height: 44px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 12px;
          border-radius: 10px;
          border: solid 1px $inputBorderColor;
          margin-right: 12.5px;
          @include Roboto-font-style(14px);
          color: $textColor;
          text-transform: capitalize;

          &.Mui-selected {
            color: $mainColor;
            border: solid 2px $mainColor;
            background-color: white;
            font-weight: 500;
          }
        }

        .MuiToggleButtonGroup-root {
          overflow-x: auto;
        }
      }

      .custom_input_style {
        margin-top: 24px;

        label {
          @include Roboto-font-style(14px);
          color: #b7c0cd;

          &.Mui-focused {
            color: $secColor;
            font-weight: 600;
          }
        }

        input {
          color: #222324;
          @include Roboto-font-style(15px);

          &::placeholder {
            color: #b7c0cd;
            opacity: 1;
            /* Firefox */
          }

          &::-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #b7c0cd;
          }

          &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #b7c0cd;
          }
        }

        .MuiInputBase-root {
          border-radius: 10px;
          height: 48px;

          .MuiOutlinedInput-notchedOutline {
            border-color: $inputBorderColor;
          }

          &.Mui-focused {
            border-color: $secColor;

            .MuiOutlinedInput-notchedOutline {
              border-color: $secColor;
              border-width: 1px !important;
            }
          }
        }
      }
    }

    .add_custom_combination_btn_style {
      margin-top: 24px;
      @include Roboto-font-style(14px);
      display: flex;
      justify-content: center;
      align-items: center;
      background: #39485b;
      color: white;
      border: 1px solid #39485b;
      height: 40px;
      border-radius: 10px;

      &.sucess_status_style {
        border: 1px solid $successColor;
        background: $successColor;
      }
    }
  }

  .kits_wrapper_style {
    margin-top: 19px;

    &.kit_error_wrapper_style {
      .MuiPaper-root {
        border: solid 1px $errorColor;
      }
    }

    img {
      width: 80px;
      height: 80px;
      border-radius: 10px;
      border: solid 2px $inputBorderColor;
    }

    h4 {
      @include Roboto-font-style(24px);
      font-weight: 600;
      color: $secColor;
      margin-left: 18px;
    }

    .MuiPaper-root {
      border-radius: 15px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px $inputBorderColor;

      .MuiButtonBase-root,
      .MuiAccordionSummary-content {
        padding: 0;
        margin: 0;
      }

      .MuiAccordionSummary-root {
        padding: 18px;
      }

      .MuiAccordionDetails-root {
        padding: 24px;
        border-top: solid 1px #d6dde6;
      }

      .MuiAccordionSummary-expandIconWrapper {
        color: #39485b;

        svg {
          width: 30px;
          height: 30px;
        }

        &.Mui-expanded {
          color: $mainColor;
        }
      }
    }
  }
}
