// to handle tablet devices dimensions media queries
@media only screen and (min-width: 600px) and (max-width: 1023px) {
  .search_store_fronts_by_name_wrapper_style {
    input {
      font-size: 11.5px !important;
    }
  }

  .filter_menu_wrapper_style {
    .search_by_product_name_wrapper_style {
      input {
        font-size: 13.5px !important;
      }
    }
  }

  .navbar_wrapper_style {
    .open_drawer_return_items_btns_wrapper_style {
      .open_drawer_btn_style,
      .return_items_btn_style {
        font-size: 12px !important;
      }
    }
  }

  .filter_menu_wrapper_style {
    .search_input_style {
      font-size: 13.5px !important;
    }
  }

  .items_combination_wrapper_style {
    .product_details_wrapper_style {
      margin-left: 0px;
      margin-top: 32px;
    }
  }

  .checkout_form_wrapper_style {
    .stepper_content_wrapper_style {
      .checkout_payment_step_wrapper_style {
        .paid_remaning_wrapper_style {
          font-size: 12.5px !important;
        }
      }
    }
  }
}
