@font-face {
  font-family: "ITC Avant Garde Gothic Std Demi Condensed";
  src: url("../fonts/ITCAvantGardeStd-DemiCn.woff2") format("woff2"),
    url("../fonts/ITCAvantGardeStd-DemiCn.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ITC book";
  src: url("../fonts/ITCAvantGardeStd-BkCn.woff2") format("woff2"),
    url("../fonts/ITCAvantGardeStd-BkCn.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

@mixin ITC-Avant-Garde-Gothic-font-style($fontSize) {
  font-family: "ITC Avant Garde Gothic Std Demi Condensed";
  font-size: $fontSize;
}

@mixin ITC-book-font-style($fontSize) {
  font-family: "ITC book";
  font-size: $fontSize;
}

@mixin Roboto-font-style($fontSize) {
  font-family: "Roboto", sans-serif !important;
  font-size: $fontSize !important;
}
