.custom_select_wrapper_style {
  position: relative;
  border-radius: 10px;
  border: solid 1px $inputBorderColor;

  &.error_msg_style {
    border: solid 1px $errorColor !important;
  }

  label {
    position: absolute;
    left: 19px;
    bottom: 37px;
    color: $secColor;
    @include Roboto-font-style(13px);
    font-weight: 600;
    background-color: white;
  }

  .custom_select_style__control {
    margin: 4px 16px;
    border: none !important;
    background-color: transparent !important;

    &:hover {
      border-color: $secColor !important;
    }

    &--is-focused {
      box-shadow: none !important;
    }
  }

  .custom_select_style__indicator {
    color: #39485b !important;
  }

  .custom_select_style__indicator-separator {
    display: none;
  }

  .custom_select_style__value-container,
  .custom_select_style__indicator {
    padding: 0 !important;
  }

  .custom_select_style__option,
  .custom_select_style__single-value,
  .custom_select_style__placeholder {
    @include Roboto-font-style(14px);
    color: #878d96 !important;
  }

  .custom_select_style__placeholder {
    color: #b7c0cd !important;
  }

  .custom_select_style__option--is-selected {
    background-color: $mainColor !important;
    color: white !important;
  }

  .custom_select_style__input-container {
    input {
      @include Roboto-font-style(14px);
      color: #878d96 !important;
    }
  }
}
