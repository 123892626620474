.calculator_wrapper_style {
  margin-top: 32px;
  max-height: 619px;

  .calculator {
    height: auto;
    padding: 20px;
    border-radius: 15px;
    border: solid 1px $inputBorderColor;
    background-color: white;
  }

  .keys-numbers {
    grid-area: keys-numbers;
    direction: rtl;
  }

  .keys-operators {
    grid-area: keys-operators;
  }

  .keys-function {
    grid-area: keys-function;
  }

  .calculator-keypad {
    display: grid;
    justify-content: end;
    grid-template-columns: repeat(4, 6rem);
    grid-template-rows: repeat(5, 6rem);
    grid-template-areas: "keys-function keys-function keys-function keys-operators""keys-numbers keys-numbers keys-numbers keys-operators""keys-numbers keys-numbers keys-numbers keys-operators""keys-numbers keys-numbers keys-numbers keys-operators""keys-numbers keys-numbers keys-numbers keys-operators";
  }

  // .operation_wrapper_style {
  //   display: flex;
  //   justify-content: end;
  //   @include Roboto-font-style(30px);
  //   color: $textColor;
  // }

  .calculator-input {
    .result {
      display: flex;
      justify-content: end;
      @include Roboto-font-style(70px);
      color: #000;
      font-weight: 300;
    }
  }

  .calculator-keypad {
    .keys-function,
    .keys-operators,
    .keys-numbers {
      button {
        @include Roboto-font-style(32px);
        text-align: center;
        margin: 10px;
        width: 76px;
        height: 76px;
        padding: 12px;
        border-radius: 15px;
      }
    }

    .keys-function {
      button {
        color: $secColor;
        background-color: $inputBorderColor;
        border: solid 1px $inputBorderColor;
      }
    }

    .keys-operators {
      button {
        border: solid 1px $inputBorderColor;
        background-color: #f6f6f6;
        color: $mainColor;
      }
    }

    .keys-numbers {
      button {
        border: solid 1px transparent;
        background-color: transparent;
        color: $secColor;
      }
    }
  }
}