.login_wrapper_style {
  width: 100%;
  height: 100%;
  // background: linear-gradient(
  //   0deg,
  //   rgba(21, 113, 177, 1) 0%,
  //   rgba(97, 167, 233, 1) 100%
  // );
  background: white;
  position: fixed;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiInput-root.MuiInput-underline {
    &:before {
      border-bottom: 1px solid #e4eaec !important;
    }

    &:after {
      border-bottom: 1px solid #3e8ef7 !important;
    }
  }

  .MuiInputLabel-root {
    font-weight: 300;
    color: #a3afb7;
    font-size: 14px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    -webkit-text-fill-color: #a3afb7;
  }

  .MuiInput-input {
    color: #757575;
    font-size: 13px;
    padding: 8px 0 5px !important;
  }

  .MuiCheckbox-root {
    color: #e4eaec;
  }

  .MuiFormControlLabel-label {
    color: #757575;
    font-size: 13px;
  }

  .MuiLink-underlineAlways {
    color: #3949ab;
    text-decoration: none;
    font-size: 13px;

    &:hover {
      text-decoration: underline;
      text-decoration-color: #3949ab;
    }
  }

  .MuiTypography-alignCenter {
    font-size: 12px;
    color: white;
  }

  .sign_in_btn_style {
    color: white !important;
    background-color: #3e8ef7 !important;
    border-color: #3e8ef7 !important;
    box-shadow: none !important;
    margin-top: 40px !important;
    height: 46px;
    text-transform: none !important;
    font-size: 16px !important;
  }

  .login_card_style {
    background-color: white;
    border-radius: 4px;
    padding: 15px;
    margin: 30px;

    img {
      padding: 20px;
      padding-bottom: 30px;
      width: 200px;
    }
  }
}