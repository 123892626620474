.modal {
  .modal_right_style {
    &.modal-dialog {
      position: absolute;
      right: 46px;
      top: 65px;
      min-width: 555px;
    }
  }

  .modal-content {
    background-color: white;
    border: 1px solid transparent;
    border-radius: 15px;
    padding: 24px;
  }

  .modal_header_style {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      color: $mainColor;
      @include Roboto-font-style(24px);
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  .modal-body {
    padding: 0 !important;
  }

  .sub_modal_title_style {
    color: #39485b;
    @include Roboto-font-style(20px);
    text-transform: capitalize;
    font-weight: 500;
    margin: 10px 0 32px 0;
  }
}

.drawer_animation_style {
  transform: translate(0%, 0);
  animation-delay: 0s;
  animation-play-state: running;
  animation-iteration-count: 1;
  animation-duration: 0.5s;
  animation-name: supportanimation;
  opacity: 1;
}

@keyframes supportanimation {
  from {
    transform: translate(200%, 0);
    opacity: 1;
  }

  to {
    transform: translate(0%, 0);
    opacity: 1;
  }
}

.modal-backdrop.show {
  background: rgba(80, 104, 136, 0.44) !important;
  opacity: 1 !important;
}