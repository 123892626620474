.navbar_wrapper_style {
  background-color: white;

  .logo_style {
    height: 64px;
  }

  .open_drawer_return_items_btns_wrapper_style {
    display: flex;

    .open_drawer_btn_style,
    .return_items_btn_style {
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px;
      border-radius: 10px;
      background-color: $mainColor;
      border: 1px solid transparent !important;
      @include Roboto-font-style(18px);
      font-weight: 500;
    }

    .open_drawer_btn_style {
      color: white !important;
    }

    .return_items_btn_style {
      color: white;
      background: $errorColor;
    }
  }

  .exit_btn_style {
    @include Roboto-font-style(20px);
    font-weight: 500;
    color: $mainColor;
  }

  .navbar_icon_wrapper_style {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: $navbarIconsBgColor;
    border-radius: 40px;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .logo_search_wrapper_style {
    padding: 26px 46px;
  }

  .time_need_help_wrapper_style {
    padding: 10px 46px;
    border-bottom: 1px solid $inputBorderColor;

    h2 {
      color: #39485b;
      @include Roboto-font-style(15px);
      font-style: italic;
    }
  }
}