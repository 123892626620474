.filter_menu_wrapper_style {
  padding: 0 46px 32px 46px;

  .filter_btn_style {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5.5px;
    border-radius: 10px;
    border: solid 2px $mainColor !important;
    background-color: $mainColor !important;
    box-shadow: none;
  }

  .search_input_style {
    height: 48px;
    border-radius: 10px;
    padding: 15px 23px;
    border: solid 1px $inputBorderColor;
    background-color: white;
    @include Roboto-font-style(15px);
    color: $textColor;
    margin-left: 10px;

    &::placeholder {
      color: $textColor;
      opacity: 1;
      /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $textColor;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $textColor;
    }
  }

  .search_by_product_name_wrapper_style {
    display: flex;
    align-items: center;
    height: 48px;
    border: 1px solid $inputBorderColor;
    border-radius: 10px;
    padding: 0 23px;
    background-color: white;

    input {
      color: $textColor;
      @include Roboto-font-style(15px);

      &::placeholder {
        color: $textColor;
        opacity: 1;
        /* Firefox */
      }

      &::-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $textColor;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $textColor;
      }
    }
  }

  .scan_bar_code_btn_style {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px;
    border-radius: 10px;
    border: solid 1px $inputBorderColor !important;
    background-color: white !important;
    margin-left: 19px;
  }

  .custom_select_style__control {
    height: 48px;
    border: 1px solid $inputBorderColor !important;
    border-radius: 10px;

    .custom_select_style__placeholder,
    .custom_select_style__single-value {
      color: $textColor;
      @include Roboto-font-style(16px);
      font-weight: 500;
      padding: 10px;
    }

    .custom_select_style__indicator {
      color: #39485b;
    }
  }
}
