.checkout_form_wrapper_style {
  h4 {
    @include Roboto-font-style(24px);
    font-weight: 600;
    color: $secColor;
    margin-bottom: 24px;
  }

  .add_items_btn_style {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-radius: 10px;
    border: solid 2px $mainColor !important;
    background-color: white !important;
    @include Roboto-font-style(20px);
    font-weight: 500;
    color: $mainColor !important;
    text-transform: capitalize;
  }

  .MuiTabs-root {
    min-height: 36px !important;
    margin-bottom: 32px;

    .MuiTabs-flexContainer {
      border-bottom: 2px solid white;
    }

    button {
      text-transform: none !important;
      min-height: 36px !important;
      padding: 8.5px 14px !important;
      @include Roboto-font-style(16px);
      color: $textColor;
      font-weight: normal;

      &.Mui-selected {
        color: $mainColor !important;
        font-weight: 500;
      }
    }

    .MuiTabs-indicator {
      background-color: $mainColor !important;
    }
  }

  .MuiStepper-root {
    .MuiStepLabel-iconContainer {
      .MuiSvgIcon-root {
        width: 40px;
        height: 40px;
        fill: transparent;
        border: solid 1px $inputBorderColor;
        border-radius: 50%;

        .MuiStepIcon-text {
          @include Roboto-font-style(12px);
          font-weight: 500;
          fill: #b7c0cd;
        }

        &.Mui-active,
        &.Mui-completed {
          fill: $mainColor;

          .MuiStepIcon-text {
            fill: white;
          }
        }
      }
    }

    .MuiStepContent-root {
      margin-left: 18px;
      padding-left: 29px;
      padding-right: 14px;
      border-left: 2px dashed $mainColor;
    }

    .MuiStepLabel-iconContainer {
      padding-right: 13px;
    }

    .MuiStepLabel-root {
      padding: 0px;
    }

    .MuiStepLabel-label {
      @include Roboto-font-style(20px);
      font-weight: 500;
      color: $textColor;

      &.Mui-active,
      &.Mui-completed {
        color: $mainColor;
      }
    }

    .MuiStepConnector-root {
      margin-left: 18px;

      .MuiStepConnector-line {
        border-color: $inputBorderColor;
        border-left-style: dashed;
        border-left-width: 2px;
        min-height: 32px;
      }

      &.Mui-completed,
      &.Mui-active {
        .MuiStepConnector-line {
          border-color: $mainColor;
        }
      }
    }
  }

  .next_step_btn_style,
  .back_to_step_btn_style {
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 12px;
    border-radius: 10px;
    @include Roboto-font-style(20px);
    font-weight: 500;
  }

  .next_step_btn_style {
    background-color: $mainColor !important;
    color: white;
  }

  .back_to_step_btn_style {
    background-color: $inputBorderColor !important;
    color: #39485b;
    margin-left: 9.5px;

    &.disabled {
      opacity: 0.4;
    }
  }

  .add_split_payment_btn_style,
  .disable_split_payment_btn_style {
    height: 48px;
    border-radius: 10px;
  }

  .add_split_payment_btn_style {
    border: 1px solid $successColor !important;
    background-color: $successColor !important;
  }

  .disable_split_payment_btn_style {
    border: 1px solid $errorColor !important;
    background-color: $errorColor !important;
  }

  .checkout_content_wrapper_style,
  .order_summary_content_wrapper_style {
    border-radius: 15px;
    border: solid 1px $inputBorderColor;
    background-color: white;
    margin-top: 24px;
  }

  .steps_content_wrapper_style {
    padding: 24px;
    height: 670px;
    overflow-y: auto;
  }

  .order_summary_content_wrapper_style {
    padding: 18px;

    .cart_step_wrapper_style {
      h1 {
        @include Roboto-font-style(24px);
        font-weight: 600;
        color: $secColor;
        margin-bottom: 24px;
      }

      .cart_items_divs_style {
        padding: 14px;
        border-radius: 15px;
        border: solid 1px $inputBorderColor;
        background-color: white;
        margin-bottom: 14px;
      }

      .product_in_cart_img_style {
        height: 64px;
        border-radius: 10px;
        border: solid 1px $inputBorderColor;
      }

      h2 {
        @include Roboto-font-style(16px);
        font-weight: 500;
        color: $secColor;
      }

      .cart_item_description_style {
        @include Roboto-font-style(14px);
        color: $textColor;
      }

      .input_Increment_decrement_wrapper_style {
        display: flex;

        p {
          @include Roboto-font-style(14px);
        }
      }

      .product_price_in_oreder_summary_step_style {
        @include Roboto-font-style(16px);
        font-weight: 500;
        color: $mainColor;
      }

      .apply_discount_btn_style,
      .cancel_discount_btn_style {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        border-radius: 10px;
        padding: 12px;
        @include Roboto-font-style(20px);
        font-weight: 500;
        color: white;
      }

      .apply_discount_btn_style {
        background-color: $mainColor;
        border-color: $mainColor;
      }

      .cancel_discount_btn_style {
        background-color: $errorColor;
        border-color: $errorColor;
      }

      .order_summary_amount_wrapper_style {
        margin-top: 24px;
        border-radius: 15px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
        border: dashed 1px #b7c0cd;
        background-color: $navbarIconsBgColor;
        padding: 24px 10px;

        hr {
          margin-top: 0;
          color: #b7c0cd;
          border-top: 1px dashed;
          opacity: 1;
        }

        p {
          @include Roboto-font-style(16px);
          color: #000;
          margin-bottom: 14px;
        }

        h3 {
          @include Roboto-font-style(14px);
          color: #000;
          margin-bottom: 14px;
        }

        .quanitiy_wrapper_style {
          h4 {
            @include Roboto-font-style(24px);
            font-weight: 600;
            color: $secColor;
            margin-bottom: 0px;
          }

          p {
            @include Roboto-font-style(24px);
            font-weight: 600;
            color: $mainColor;
            margin-bottom: 0px;
          }
        }
      }

      .discount_amount_style {
        opacity: 0.5;
        @include Roboto-font-style(16px);
        font-weight: 500;
        color: #000;
        text-decoration: line-through;
      }

      .discount_percentage_style {
        color: #e74141;

        h3 {
          color: #e74141;
        }
      }

      .product_divs_wrapper_style {
        height: 400px;
        overflow-y: auto;
      }
    }
  }

  .stepper_content_wrapper_style {
    margin-top: 24px;

    .step_form_wrapper_style {
      border-radius: 10px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px $inputBorderColor;
      padding: 24px;
      margin-left: 14px;
      margin-bottom: 32px;
    }

    .step_form_btns_wrapper_style {
      margin-left: 14px;
    }

    .guest_wrapper_style {
      margin-left: 14px;
      margin-bottom: 32px;
      border: 1px solid $navbarIconsBgColor;
      border-radius: 10px;
      background-color: $navbarIconsBgColor;
      padding: 8px;

      p {
        @include Roboto-font-style(16px);
        font-weight: 500;
        color: $textColor;
        margin-bottom: 0;
      }
    }

    .checkbox_label_style {
      .MuiFormControlLabel-label {
        @include Roboto-font-style(14px);
        font-weight: 500;
        color: #39485b;
      }

      .MuiSvgIcon-root {
        height: 30px !important;
        width: 30px !important;
      }
    }

    .shipping_form_wrapper_style {
      .radio_label_style {
        margin-right: 18.8px;
        margin-bottom: 24px;
      }

      h1 {
        @include Roboto-font-style(14px);
        color: $secColor;
        font-weight: 600;
        margin-bottom: 0;
      }

      p {
        @include Roboto-font-style(14px);
        color: #39485b;
        margin-bottom: 0px;
      }

      .exiting_address_style {
        cursor: pointer;
        border-radius: 10px;
        padding: 16px;
        border: solid 1px $inputBorderColor;
        margin-bottom: 24px;

        &.active {
          border: 1px solid $secColor;
        }
      }

      .address_btn_style {
        width: fit-content;
        width: -moz-fit-content;
        @include Roboto-font-style(16px);
        font-weight: 500;
        color: $mainColor;
        cursor: pointer;
      }

      .add_new_shipping_address_wrapper_style {
        margin-left: 18.8px;
        margin-top: 26.5px;

        .add_new_addresss_btn_style {
          @include Roboto-font-style(20px);
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $mainColor;
          height: 44px;
          padding: 12px;
          margin-top: 18px;
          border-radius: 10px;
          background-color: white !important;
          border: 2px solid $mainColor !important;
        }
      }
    }

    .checkout_payment_step_wrapper_style {

      .pay_in_full_btn_style,
      .split_payment_btn_style {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: solid 1px #f2f6f8;
        background-color: transparent;
        @include Roboto-font-style(16px);
        color: $textColor;
        padding: 8.5px;

        &.active {
          border-bottom: solid 2px $mainColor;
          color: $mainColor;
          font-weight: 600;
        }
      }

      .change_wrapper_style {
        height: 48px;
        padding: 16px;
        border-radius: 10px;
        border: solid 1px $inputBorderColor;
        background-color: $navbarIconsBgColor;

        label,
        p {
          @include Roboto-font-style(14px);
          margin-bottom: 0;
        }

        label {
          color: $secColor;
          font-weight: 600;
        }

        p {
          color: #39485b;
        }
      }

      .connect_to_terminal_btn_style {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $secColor !important;
        border: solid 2px $secColor;
        border-radius: 20px;
        @include Roboto-font-style(16px);
        color: white;

        &.reader_is_connected_btn_style {
          background-color: $successColor !important;
          border: solid 2px $successColor;
          cursor: default !important;
        }
      }

      .split_total_items_btn_style {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $textColor;
        padding: 14px;
        border-radius: 8px;
        background-color: #f6f6f6;
        border: 1px solid #f6f6f6;
        height: 48px;
        @include Roboto-font-style(15px);
        font-weight: 600;
        margin: 26px 0 26px 0;

        &.active {
          box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.04),
            0 3px 8px 0 rgba(0, 0, 0, 0.12);
          border-radius: 6px;
          border: solid 2px $mainColor;
          background-color: #fff;
          color: $mainColor;
        }
      }

      h2 {
        @include Roboto-font-style(16px);
        font-weight: 600;
        color: #000;
        margin-bottom: 24px;
      }

      .paid_remaning_wrapper_style {
        @include Roboto-font-style(16px);
        font-weight: 600;
        color: #39485b;
        height: 48px;
        padding: 14px;
        border-radius: 10px;
        border: 1px solid $navbarIconsBgColor;
        background-color: $navbarIconsBgColor;
      }

      .pay_cash_or_credit_btn_style {
        width: fit-content;
        width: -moz-fit-content;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;
        border-radius: 10px;
        border: 1px solid $mainColor !important;
        background-color: $mainColor !important;
        @include Roboto-font-style(20px);
        font-weight: 500;
        color: white;
        text-transform: capitalize;
        margin-top: 24px;

        &.disabled {
          border: 1px solid #b7c0cd !important;
          background-color: #b7c0cd !important;
        }
      }

      .split_by_items_payment_wrapper_style {
        height: 110px;
        overflow-y: auto;
        margin: 0 0 24px;
        padding: 10px;
        border-radius: 10px;
        border: solid 1px $inputBorderColor;

        .items_split_wrapper_style {
          p {
            @include Roboto-font-style(14px);
            font-weight: 500;
            color: #39485b;
            margin-bottom: 0;
          }

          &.active {
            p {
              color: #39485b;
            }
          }
        }

        .paid_items_wrapper_style {
          padding: 10px 14px;

          p {
            @include Roboto-font-style(14px);
            font-weight: 500;
            color: $textColor;
            margin-bottom: 0;
          }

          .paid_badge_style {
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 20px;
            border-radius: 8px;
            background-color: #dcf9d7;
            @include Roboto-font-style(10px);
            font-weight: 500;
            color: #313030;
          }
        }
      }
    }
  }

  .payment_completed_wrapper_style {
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #dcebfe;
    background-color: #dcebfe;
    padding: 10px;

    p {
      @include Roboto-font-style(16px);
      font-weight: 600;
      color: $secColor;
      margin-bottom: 0;
      margin-left: 10px;
    }
  }

  .complete_process_btn_style {
    padding: 24px;
    border-radius: 15px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    border-top: solid 1px $inputBorderColor;
    background-color: white;

    button {
      cursor: default;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 64px;
      width: 100%;
      @include Roboto-font-style(20px);
      font-weight: 500;
      border-radius: 10px;
      background-color: #b7c0cd !important;
      padding: 20.5px;
      border-color: #b7c0cd !important;
      color: white !important;
    }

    .active_complete_process_btn_style {
      cursor: pointer;
      background-color: $mainColor !important;
      border-color: $mainColor !important;
    }
  }
}