.cart_wrapper_style {
  min-height: 759px;
  border-radius: 15px;
  border: solid 1px $inputBorderColor;
  background-color: white;

  .cart_header_style {
    display: flex;
    padding: 18px;
    justify-content: space-between;
    align-items: center;

    p {
      @include Roboto-font-style(24px);
      font-weight: 600;
      color: $secColor;
    }

    h2 {
      cursor: pointer;
      @include Roboto-font-style(14px);
      font-weight: 500;
      color: $errorColor;
      margin-bottom: 0;
    }
  }

  .cart_items_wrapper_style {
    height: 610px;
    padding: 18px;
    overflow-y: auto;

    .cart_items_divs_style {
      border-radius: 15px;
      border: solid 1px $inputBorderColor;
      margin-bottom: 14px;
      padding: 10px;

      .input_Increment_decrement_wrapper_style {
        display: flex;
        align-items: center;
      }

      .product_in_cart_img_style {
        border-radius: 10px;
        border: solid 1px transparent;
      }
    }

    h2 {
      @include Roboto-font-style(16px);
      font-weight: 500;
      color: #2e2e2e;
      margin-bottom: 4px;
    }

    .cart_item_description_style,
    .cart_item_custom_text_style,
    .kit_item_style {
      @include Roboto-font-style(14px);
      color: $textColor;
    }
  }

  .cart_bottom_style {
    margin: 18px;
    padding: 10px;
    border-radius: 15px;
    border: dashed 2px #a9a9a9;
    background-color: $navbarIconsBgColor;

    h4,
    h3 {
      margin-bottom: 0;
      color: black;
    }

    h4 {
      @include Roboto-font-style(16px);
    }

    h3 {
      @include Roboto-font-style(14px);
    }

    h6,
    h5 {
      @include Roboto-font-style(24px);
      font-weight: 600;
    }

    h6 {
      color: $secColor;
    }

    h5 {
      color: $mainColor;
    }

    hr {
      margin: 30px 0 20px;
      color: #a9a9a9;
      border-top: 2px dashed;
      opacity: 1;
    }

    .checkout_cart_btn_style {
      margin-top: 30px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      padding: 12px;
      border: 1px solid $mainColor !important;
      background-color: $mainColor !important;
      @include Roboto-font-style(20px);
      font-weight: 500;
      color: white;
      width: 100%;
    }
  }

  .empty_cart_wrapper_style {
    &.cart_bottom_style {
      border-radius: 0 !important;
      border: none !important;
      background-color: transparent !important;

      .checkout_cart_btn_style {
        background-color: #b7c0cd !important;
        border-color: #b7c0cd !important;
        cursor: default !important;
      }
    }
  }
}
